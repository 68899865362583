<template>
  <div class="mx-3 my-0 py-0 ">

    <v-card class="d-flex flex-wrap " flat>

      <div class="my-0 py-0 mx-1">
        <v-card flat width="230px ">
          <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="true" :nudge-right="40"
            transition="scale-transition" color="deep-orange lighten-2">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field color="deep-orange lighten-2" dense outlined v-model="dateFrom" label="מתאריך"
                prepend-inner-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dateFrom" no-title @input="menuFrom = false" locale="he-IL"
              lang="he"></v-date-picker>
          </v-menu>
        </v-card>
      </div>
      <div class="my-0 py-0 mx-1">
        <v-card flat width="230px">
          <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" color="deep-orange lighten-2">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateTo" label="עד תאריך" prepend-inner-icon="mdi-calendar" clearable readonly
                v-bind="attrs" v-on="on" color="deep-orange lighten-2" dense outlined class="py-0"></v-text-field>
            </template>
            <v-date-picker v-model="dateTo" no-title @input="menuTo = false" locale="he-IL" lang="he"></v-date-picker>
          </v-menu>
        </v-card>
      </div>
      <div class="my-0 pb-7 mx-3 py-0">
        <v-divider vertical></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["val", "type"],
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      menuFrom: false,
      menuTo: false
    };
  },
  watch: {
    dateFrom() {
      this.emitFilter();
    },
    dateTo() {
      this.emitFilter();
    }
  },
  methods: {
    emitFilter() {
      this.$emit('update-filter', post => {
        // if (!post.values[`${this.val.id}`].value) {
        //   // console.log("no value", post.values);
        //   return false;
        // }

        const filterDateFrom = this.dateFrom ? new Date(this.dateFrom) : null;
        const filterDateTo = this.dateTo ? new Date(this.dateTo) : null;

        if (!filterDateFrom && !filterDateTo) return true;


        if (!post.values[`${this.val.id}`] || !post.values[`${this.val.id}`].value) return false;
        const postDateFrom = post.values[`${this.val.id}`].value.dateFrom ? new Date(post.values[`${this.val.id}`].value.dateFrom) : null;
        const postDateTo = post.values[`${this.val.id}`].value.dateTo ? new Date(post.values[`${this.val.id}`].value.dateTo) : null;

        // Если у поста только дата начала, проверяем, попадает ли она в диапазон фильтра
        if (postDateFrom && !postDateTo) {
          if (filterDateFrom && postDateFrom < filterDateFrom) return false;
          if (filterDateTo && postDateFrom > filterDateTo) return false;
          return true;
        }

        // Проверяем, пересекается ли диапазон дат поста с диапазоном фильтра
        if (postDateFrom && postDateTo) {
          if (filterDateFrom && postDateTo < filterDateFrom) return false;
          if (filterDateTo && postDateFrom > filterDateTo) return false;
          return true;
        }

        // Если у поста нет дат, пропускаем его через фильтр
        return true;
      });
    }
  }
}
</script>
