<template>
    <div>
        <div v-if="(!posts || posts.length === 0) && loadedPosts">
            אין מודעות
        </div>
        <div v-if="posts.length > 0 && loadedPosts">
            <v-select v-if="isPostTypes" v-model="selectedPostTypeId" :items="postTypesOptions" label="בחר סוג מודעה"
                clearable multiple no-data-text="ריק" return-object></v-select>

            <!--filters-->
            <div v-if="selectedPostTypeId.length > 0" class="d-flex flex-wrap">
                <div v-for="filterType in filterFields" :key="filterType.name">
                    <div v-if="readyFilters.includes(filterType.type)">
                        <component :is="filterType.type" :val="filterType"
                            @update-filter="updateFilter(filterType.name, $event)">
                        </component>
                    </div>
                </div>
            </div>
            <div class="my-3">
                <v-btn :disabled="selectedPostTypeId.length > 1" v-if="selectedPostTypeId.length > 0" block
                    @click="showExtarFilters = !showExtarFilters" color="primary">
                    <v-icon v-if="showExtarFilters && selectedPostTypeId.length === 1">mdi-minus</v-icon>
                    <v-icon v-if="!showExtarFilters && selectedPostTypeId.length === 1">mdi-plus</v-icon>
                    <span v-if="selectedPostTypeId.length === 1">אפשרויות סינון נוספות</span>
                    <span v-if="selectedPostTypeId.length > 1">מוגבל לסוג מודעה אחד</span>
                </v-btn>
            </div>
            <div v-if="selectedPostTypeId.length === 1 && showExtarFilters" class="d-flex flex-wrap">
                <div v-for="filterType in noneFilterFields" :key="filterType.name">
                    <div v-if="readyFilters.includes(filterType.type)">
                        <component :is="filterType.type" :val="filterType"
                            @update-filter="updateFilter(filterType.name, $event)">
                        </component>
                    </div>
                </div>
            </div>
            <v-divider></v-divider>
            <!--filters end-->

            <div v-if="selectedPostTypeId.length > 0">
                <!-- HEADERS -->
                <v-card dark color="primary" class="my-3 pa-1 rounded-lg">
                    <v-card-text class="my-1 pa-1 px-9 d-flex flex-wrap justify-space-between">
                        <span class="span-width">
                            <!-- <v-icon @click="doSortByStar()" color="grey">mdi-star</v-icon>
                            <v-icon small v-if="sortByStar">
                                {{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon> -->
                        </span>
                        <span class="span-width showPointer" :style="'color:white'" @click="dosortBylastUpdated()">
                            תאריך פרסום המודעה
                            <v-icon small v-if="sortBylastUpdated">
                                {{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon>
                        </span>
                        <span class="span-width showPointer" :style="'color:white'"
                            v-for="field in fieldsInTableFromPostFields" :key="field.id" @click="changeSort(field)">
                            {{ field.name }}
                            <v-icon small v-if="sortColumn === field">
                                {{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon>
                        </span>
                        <span class="span-width" :style="'color:white'">סוג מודעה</span>
                        <!-- <span class="span-width">
                            <v-icon class="float-left" color="grey">mdi-menu-down</v-icon>
                        </span> -->
                    </v-card-text>
                </v-card>
            </div>
            <div v-if="selectedPostTypeId.length > 0">
                <!-- TABLE CONTENT -->
                <v-card v-for="(post) in filteredPosts" :key="post.id" class="my-3 pa-1 rounded-lg showPointer"
                    :color="(isPostViewedByMe(post.id) && !post.opened) ? '#f0f0f0' : ''">
                    <v-card-text @click="openPost(post.id)"
                        class="my-1 pa-1 px-9 d-flex flex-wrap justify-space-between">
                        <span class="span-width">
                            <v-icon @click="add_remove_star(post.id)"
                                :color="isStaredPost(post.id) ? '#FFD700' : '#E0E0E0'">mdi-star</v-icon>
                        </span>
                        <span class="span-width">{{ formatDate(post.lastUpdated) }}</span>
                        <span v-for="field in fieldsInTableFromPostFields" :key="field.id" class="span-width">
                            {{ getFieldValueByfieldId(post, field) }}
                        </span>
                        <span class="span-width">{{ getPostType(post.postTypeId).name }}</span>
                        <span class="span-width">
                            <!-- <v-btn icon @click="post.opened = !post.opened" class="float-left"> -->
                            <v-icon class="float-left" v-if="post.opened" color="primary">mdi-menu-up</v-icon>
                            <v-icon class="float-left" v-if="!post.opened" color="primary">mdi-menu-down</v-icon>
                            <!-- </v-btn> -->
                        </span>
                    </v-card-text>
                    <v-card flat v-if="post.opened">
                        <v-card-text>
                            <div v-for="val, index in post.values" :key="index">
                                <showOneField v-if="val.value"
                                    :fieldValues="{ name: val.key, value: val.value, type: getFieldType(val.key) }" />
                            </div>
                            <div>
                                <span class="span-width text-h6">
                                    תאריך פרסום המודעה:
                                </span> {{ formatDate(post.created) }}

                            </div>
                            <v-divider v-if="post.contactPersonName || post.contactPersonphoneNumber"
                                class="my-5"></v-divider>
                            <div>
                                <posterInfo :uid="post.uid" :label="'פרטי המפרסם:'" />
                            </div>
                            <div v-if="post.contactPersonName">
                                <span class="span-width text-h6">
                                    איש קשר:
                                </span> {{ post.contactPersonName }}
                            </div>
                            <div v-if="post.contactPersonphoneNumber">
                                <span class="span-width text-h6">
                                    טלפון:
                                </span> {{ post.contactPersonphoneNumber }}
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <router-link :to="{ name: 'PostDetail', params: { id: post.id } }">
                                <v-btn class="ma-5" color="primary">לפרטי המודעה</v-btn>
                            </router-link>
                            <v-spacer></v-spacer>
                            <ProposalComponent class="mx-9" :post="post" :UID="UID" :postId="post.id" />
                        </v-card-actions>
                    </v-card>
                </v-card>
            </div>

            <div v-if="selectedPostTypeId.length === 0">
                <!-- HEADERS -->
                <v-card v-if="filteredPosts[0]" dark color="primary" class="my-3 pa-1 rounded-lg">
                    <v-card-text class="my-1 pa-1 px-9 d-flex flex-wrap justify-space-between">
                        <span class="span-width">
                            <!-- <v-icon @click="doSortByStar()" color="grey">mdi-star</v-icon>
                            <v-icon small v-if="sortByStar">
                                {{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon> -->
                        </span>
                        <span class="span-width showPointer" :style="'color:white'" @click="dosortBylastUpdated()">
                            תאריך פרסום המודעה
                            <v-icon small v-if="sortBylastUpdated">
                                {{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon>
                        </span>
                        <span class="span-width showPointer" :style="'color:white'"
                            v-for="field in fieldsInTableFromPostFieldsByField(getPostType(filteredPosts[0].postTypeId))"
                            :key="field.id" @click="changeSort(field)">
                            {{ field.name }}
                            <v-icon small v-if="sortColumn === field">
                                {{ sortOrder === 'asc' ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                            </v-icon>
                        </span>
                        <span class="span-width" :style="'color:white'">סוג מודעה</span>
                        <span class="span-width">
                            <!-- <v-icon class="float-left" color="grey">mdi-menu-down</v-icon> -->
                        </span>
                    </v-card-text>
                </v-card>

                <!-- TABLE CONTENT -->
                <v-card v-for="(post) in filteredPosts" :key="post.id" class="my-3 pa-1 rounded-lg showPointer"
                    :color="(isPostViewedByMe(post.id) && !post.opened) ? '#f0f0f0' : ''">
                    <v-card-text @click="openPost(post.id)"
                        class="my-1 pa-1 px-9 d-flex flex-wrap justify-space-between">
                        <span class="span-width">
                            <v-icon @click="add_remove_star(post.id)"
                                :color="isStaredPost(post.id) ? '#FFD700' : '#E0E0E0'">mdi-star</v-icon>
                        </span>
                        <span class="span-width">{{ formatDate(post.lastUpdated) }}</span>
                        <span class="span-width"
                            v-for="field in fieldsInTableFromPostFieldsByField(getPostType(post.postTypeId))"
                            :key="field.id">
                            <template v-if="getFieldValueByfieldId(post, field)">
                                {{ getFieldValueByfieldId(post, field) }}
                            </template>

                            <template v-else>
                                <span class="span-width">-</span>
                            </template>
                        </span>

                        <span class="span-width">{{ getPostType(post.postTypeId).name }}</span>
                        <span class="span-width">
                            <!-- <v-btn icon @click="post.opened = !post.opened" class="float-left"> -->
                            <v-icon class="float-left" v-if="post.opened" color="primary">mdi-menu-up</v-icon>
                            <v-icon class="float-left" v-if="!post.opened" color="primary">mdi-menu-down</v-icon>
                            <!-- </v-btn> -->
                        </span>
                    </v-card-text>
                    <v-card v-if="post.opened" max-width="90%" class="mx-auto mb-9">
                        <v-card-text>
                            <div v-for="val, key, index in post.values" :key="index">
                                <showOneField v-if="val.value"
                                    :fieldValues="{ name: val.key, value: val.value, type: getFieldType(val.key) }" />
                            </div>
                            <div>
                                <span class="span-width text-h6">
                                    תאריך פרסום המודעה:
                                </span> {{ formatDate(post.created) }}

                            </div>
                            <v-divider v-if="post.contactPersonName || post.contactPersonphoneNumber"
                                class="my-5"></v-divider>
                            <div>
                                <posterInfo :uid="post.uid" :label="'פרטי המפרסם:'" />
                            </div>
                            <div v-if="post.contactPersonName">
                                <span class="span-width text-h6">
                                    איש קשר:
                                </span> {{ post.contactPersonName }}
                            </div>
                            <div v-if="post.contactPersonphoneNumber">
                                <span class="span-width text-h6">
                                    טלפון:
                                </span> {{ post.contactPersonphoneNumber }}
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <router-link :to="{ name: 'PostDetail', params: { id: post.id } }">
                                <v-btn class="ma-5" color="primary">לפרטי המודעה</v-btn>
                            </router-link>
                            <v-spacer></v-spacer>
                            <ProposalComponent class="mx-9" :post="post" :UID="UID" :postId="post.id" />
                        </v-card-actions>
                    </v-card>
                </v-card>
            </div>
            <v-divider class="my-5"></v-divider>


        </div>
    </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

import showOneField from "@/components/showRecord/showOneField.vue";
import NumberInput from "./filters/NumberInput.vue";
import CheckBoxInput from "./filters/CheckBoxInput.vue";
import DateRangeInput from "./filters/DateRangeInput.vue";
import PlaceInput from "./filters/PlaceInput.vue";
import SelectOneInput from "./filters/SelectOneInput.vue";
import SelectMultipleInput from "./filters/SelectMultipleInput.vue";

import ProposalComponent from "@/components/proposals/ProposalComponent.vue";
import posterInfo from "@/components/info/userInfo.vue";


import { format } from 'date-fns'

export default {
    props: {
        initiatedPostTypeId: {
            type: String,
            default: ''
        },
        personalMode: {
            type: Boolean,
            default: false
        },
        proposalsMode: {
            type: Boolean,
            default: false
        },
        showOnlyStared: {
            type: Boolean,
            default: false
        },
        postsStatus: {
            type: String,
            default: 'active'
        }
    },
    data() {
        return {
            posts: [],
            postTypes: [],
            postFields: [],
            placeFrom: null,
            radiusFrom: 0,
            placeTo: null,
            radiusTo: 0,
            selectedPostTypeId: [],
            componentsCache: {},
            readyFilters: ["NumberInput", "CheckBoxInput", "DateRangeInput", "PlaceInput", "SelectOneInput", "SelectMultipleInput"],
            filters: {}, // Активные фильтры
            //sorting
            sortColumn: null,
            sortOrder: 'desc', // или 'desc'
            fieldsWeights: ["primary", "regular", "secondary", "price"],
            showExtarFilters: false,
            loadedPosts: false,
            myStaredPosts: [],
            sortByStar: false,
            sortBylastUpdated: true,
            postsViewed: [],
        }
    },
    components: {
        showOneField,
        NumberInput,
        CheckBoxInput,
        DateRangeInput,
        PlaceInput,
        SelectOneInput,
        SelectMultipleInput,
        ProposalComponent,
        posterInfo,
    },
    async created() {
        await this.getPostsViewed()
        await this.getPosts()
        await this.getPostTypes()
        await this.getPostFields()
        await this.getMyStaredPosts()

        // this.sortBylastUpdated = true;
        // this.sortOrder = 'desc';
    },
    computed: {
        ...mapGetters(["user", "UID"]),
        fieldsForTable() {
            return this.postFields.filter(field => field.inTable)
        },
        FieldsForSelectedPostType() {
            console.log('selectedPostTypeId', this.selectedPostTypeId)
            if (this.selectedPostTypeId.length === 0) return []
            let postType = this.getPostType(this.selectedPostTypeId.value)
            if (!postType) return []
            let ret = []
            let fields_tmp = JSON.parse(JSON.stringify(postType.postTypeExtended))
            //sort fields_tmp by weights and by order inside weights
            fields_tmp.sort((a, b) => {
                let weightA = this.fieldsWeights.indexOf(a.weight)
                let weightB = this.fieldsWeights.indexOf(b.weight)
                if (weightA < weightB) {
                    return -1
                }
                if (weightA > weightB) {
                    return 1
                }
                //weightA == weightB
                let orderA = a.order
                let orderB = b.order
                if (orderA < orderB) {
                    return -1
                }
                if (orderA > orderB) {
                    return 1
                }
                return 0
            })
            fields_tmp.forEach(fieldId => {
                let field = this.postFields.find(pf => pf.id == fieldId.id)
                if (field) {
                    ret.push(field)
                }
            });
            return ret
        },
        filterFields() {
            // console.log('filterFields 1')
            // if (this.FieldsForSelectedPostType.length <= 0) return []
            console.log('filterFields 1')
            if (this.selectedPostTypeId.length <= 0) return []
            // console.log('this.selectedPostTypeId', this.selectedPostTypeId)
            // console.log('this.selectedPostType', this.selectedPostType)
            let postType = this.getPostType(this.selectedPostTypeId)
            console.log('postType', postType)
            if (!postType || postType.length <= 0) return []
            let ret = []
            //postType is an array, i need to accumulate all postTypeExtended from all postTypes, but no duplicates and put them in let fields_tmp
            let fields_tmp = []
            if (Array.isArray(postType) && postType.length > 0) {

                postType.forEach(pt => {
                    pt.postTypeExtended.forEach(fieldId => {
                        let field = fields_tmp.find(f => f.id == fieldId.id)
                        if (!field) {
                            fields_tmp.push(fieldId)
                        }
                    });
                });
            } else {
                fields_tmp = JSON.parse(JSON.stringify(postType.postTypeExtended))
            }

            // fields_tmp = JSON.parse(JSON.stringify(postType.postTypeExtended))
            //sort fields_tmp by weights and by order inside weights
            fields_tmp.sort((a, b) => {
                let weightA = this.fieldsWeights.indexOf(a.weight)
                let weightB = this.fieldsWeights.indexOf(b.weight)
                if (weightA < weightB) {
                    return -1
                }
                if (weightA > weightB) {
                    return 1
                }
                //weightA == weightB
                let orderA = a.order
                let orderB = b.order
                if (orderA < orderB) {
                    return -1
                }
                if (orderA > orderB) {
                    return 1
                }
                return 0
            })
            fields_tmp.forEach(fieldId => {
                let field = this.postFields.find(pf => pf.id == fieldId.id && fieldId.filter === true)
                if (field) {
                    ret.push(field)
                }
            });
            return ret
        },
        noneFilterFields() {
            // if (this.FieldsForSelectedPostType.length <= 0) return []
            if (this.selectedPostTypeId.length <= 0) return []
            let postType = this.getPostType(this.selectedPostTypeId)
            if (!postType || postType.length <= 0) return []
            let ret = []
            let fields_tmp = []
            if (Array.isArray(postType) && postType.length > 0) {

                postType.forEach(pt => {
                    pt.postTypeExtended.forEach(fieldId => {
                        let field = fields_tmp.find(f => f.id == fieldId.id)
                        if (!field) {
                            fields_tmp.push(fieldId)
                        }
                    });
                });
            } else {
                fields_tmp = JSON.parse(JSON.stringify(postType.postTypeExtended))
            }
            //sort fields_tmp by weights and by order inside weights
            fields_tmp.sort((a, b) => {
                let weightA = this.fieldsWeights.indexOf(a.weight)
                let weightB = this.fieldsWeights.indexOf(b.weight)
                if (weightA < weightB) {
                    return -1
                }
                if (weightA > weightB) {
                    return 1
                }
                //weightA == weightB
                let orderA = a.order
                let orderB = b.order
                if (orderA < orderB) {
                    return -1
                }
                if (orderA > orderB) {
                    return 1
                }
                return 0
            })
            fields_tmp.forEach(fieldId => {
                let field = this.postFields.find(pf => pf.id == fieldId.id && (fieldId.filter === false || fieldId.filter === undefined) && fieldId.extraFilter === true)
                if (field) {
                    ret.push(field)
                }
            });
            return ret
        },
        filteredPosts() {
            if (!this.posts) return []
            if (this.posts.length <= 0) return []
            let ret = this.posts.filter(post => this.filterByType(post));
            // console.log('filteredPosts', ret)
            ret = ret.filter(post => {
                return Object.values(this.filters).every(filterFn => filterFn(post));
            });
            // console.log('filteredPosts but not sorted', ret)

            if (this.sortColumn && this.sortOrder) {
                ret.sort((a, b) => {
                    let valueA, valueB;

                    switch (this.sortColumn.type) {
                        case 'postTypeId':
                            valueA = this.getPostType(a.postTypeId).name.toLowerCase();
                            valueB = this.getPostType(b.postTypeId).name.toLowerCase();
                            break;
                        case 'PlaceInput':
                            valueA = this.getCorrectShortName(a.values[this.sortColumn.id].value).toLowerCase();
                            valueB = this.getCorrectShortName(b.values[this.sortColumn.id].value).toLowerCase();
                            break;
                        case 'DateRangeInput':
                            valueA = this.getDateFromForSorting(a.values[this.sortColumn.id].value);
                            valueB = this.getDateFromForSorting(b.values[this.sortColumn.id].value);
                            break;
                        default:
                            console.log('default')
                            //how to handle the situation that there is no value for this field? (a.values[this.sortColumn.id] is undefined)

                            valueA = a.values[this.sortColumn.id] ? a.values[this.sortColumn.id].value : '';
                            valueB = b.values[this.sortColumn.id] ? b.values[this.sortColumn.id].value : '';
                    }

                    return this.sortOrder === 'asc' ? valueA < valueB ? -1 : (valueA > valueB ? 1 : 0) : valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
                });
            }
            if (this.sortByStar && this.sortOrder) {
                // console.log('sortByStar')
                //sort by stared posts, ascending or descending
                ret.sort((a, b) => {
                    let valueA = this.isStaredPost(a.id);
                    let valueB = this.isStaredPost(b.id);
                    return this.sortOrder === 'asc' ? valueA < valueB ? -1 : (valueA > valueB ? 1 : 0) : valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
                });
            }
            if (this.sortBylastUpdated && this.sortOrder) {
                // console.log('sortBylastUpdated')
                //sort by lastUpdated, ascending or descending
                ret.sort((a, b) => {
                    let valueA = a.lastUpdated;
                    let valueB = b.lastUpdated;
                    return this.sortOrder === 'asc' ? valueA < valueB ? -1 : (valueA > valueB ? 1 : 0) : valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
                });
            }
            if (this.showOnlyStared) {
                ret = ret.filter(post => this.isStaredPost(post.id));
            }
            return ret;
        },
        isPostTypes() {
            return this.postTypes.length > 0;
        },
        postTypesOptions() {
            return this.postTypes.map(postType => ({
                text: postType.name,
                value: postType.id
            }));
        },
        selectedPostType() {
            if (this.selectedPostTypeId.length === 0) return null
            if (this.selectedPostTypeId.length === 1) return this.getPostType(this.selectedPostTypeId[0].value)
            //this.selectedPostTypeId is an array, i need an array of their value values
            if (this.selectedPostTypeId.length > 1) {
                let ret = []
                this.selectedPostTypeId.forEach(id => {
                    let postType = this.getPostType(id.value)
                    if (postType) {
                        ret.push(postType)
                    }
                });
                console.log('ret', ret)
                return ret
            }
            return null
            // return this.getPostType(this.selectedPostTypeId.value) !!!!!!!!!!!!!!
        },
        fieldsInTable() {
            if (!this.selectedPostType) return []
            // console.log('this.selectedPostType', this.selectedPostType)
            //if this.selectedPostType is an array, accumulate all fieldsInTable from all postTypes
            let ret = []
            if (!Array.isArray(this.selectedPostType)) {
                ret = this.selectedPostType.postTypeExtended.filter(field => field.inTable);
            } else {
                this.selectedPostType.forEach(postType => {
                    let fieldsInTable = postType.postTypeExtended.filter(field => field.inTable);
                    //for each field in fieldsInTable, check if it is already in ret, if not, push it to ret
                    fieldsInTable.forEach(field => {
                        let fieldInRet = ret.find(f => f.id == field.id)
                        if (!fieldInRet) {
                            ret.push(field)
                        }
                    });
                });
            }
            //sort ret by weights and by order inside weights
            ret.sort((a, b) => {
                let weightA = this.fieldsWeights.indexOf(a.weight)
                let weightB = this.fieldsWeights.indexOf(b.weight)
                if (weightA < weightB) {
                    return -1
                }
                if (weightA > weightB) {
                    return 1
                }
                //weightA == weightB
                let orderA = a.order
                let orderB = b.order
                if (orderA < orderB) {
                    return -1
                }
                if (orderA > orderB) {
                    return 1
                }
                return 0
            })
            return ret
        },
        fieldsInTableFromPostFields() {
            if (this.fieldsInTable.length <= 0) return []
            let ret = []
            this.fieldsInTable.forEach(field => {
                let fieldFromPostFields = this.postFields.find(pf => pf.id == field.id)
                if (fieldFromPostFields) {
                    ret.push(fieldFromPostFields)
                }
            });
            return ret
        },
    },
    methods: {
        async getContactPerson(post) {
            let contactPerson = post.contactPerson;
            // console.log('contactPerson from: ', contactPerson)
            // console.log('post!!!!!!!!!!!!!!', post)
            let ret = '';
            if (contactPerson) {
                await fire.database().ref('users/' + contactPerson + '/info').once('value', snapshot => {
                    console.log("contactPerson to return: ", snapshot.val());
                    ret = snapshot.val();
                });
                return ret;
            }
            else return ret;
        },
        fieldsInTableFromPostFieldsByField(field) {
            if (!field) return []
            let fieldsInTable = field.postTypeExtended.filter(field => field.inTable)
            //sort fieldsInTable by weights and by order inside weights
            fieldsInTable.sort((a, b) => {
                let weightA = this.fieldsWeights.indexOf(a.weight)
                let weightB = this.fieldsWeights.indexOf(b.weight)
                if (weightA < weightB) {
                    return -1
                }
                if (weightA > weightB) {
                    return 1
                }
                //weightA == weightB
                let orderA = a.order
                let orderB = b.order
                if (orderA < orderB) {
                    return -1
                }
                if (orderA > orderB) {
                    return 1
                }
                return 0
            })
            // console.log('fieldsInTable', fieldsInTable)
            if (fieldsInTable.length <= 0) return []
            let ret = []
            fieldsInTable.forEach(field => {
                let fieldFromPostFields = this.postFields.find(pf => pf.id == field.id)
                if (fieldFromPostFields) {
                    ret.push(fieldFromPostFields)
                }
            });
            return ret
        },

        updateFilter(filterType, filterFn) {
            // console.log(filterType, filterFn);
            this.$set(this.filters, filterType, filterFn);
        },
        async getPosts() {
            console.log('getPosts')
            let viewMessage = this;
            let itemRef = null;
            if (!this.proposalsMode) {
                if (!this.personalMode) {
                    itemRef = await fire.database().ref('admin/posts').orderByChild('status').equalTo(this.postsStatus);
                } else {
                    //get itemRef = await fire.database().ref('admin/posts');, but only for the current user (UID)
                    itemRef = await fire.database().ref('admin/posts').orderByChild('uid').equalTo(this.UID);
                }

                await itemRef.on('value', async (snapshot) => {
                    viewMessage.loadedPosts = false
                    let items = snapshot.val();
                    if (items) {
                        let newState = [];
                        for (let item in items) {
                            //filter by status (this.postsStatus), make the filtration locally
                            if (items[item].status === this.postsStatus) {
                                if (items[item].values && items[item].postTypeId) {
                                    // let posterInfo = await this.getPosterInfo(items[item].uid);
                                    // console.log('posterInfo', posterInfo)
                                    let contactPersonName_tmp = "";
                                    let contactPersonphoneNumber_tmp = "";
                                    if (items[item].contactPerson) {
                                        let contactPerson_tmp = "";
                                        contactPerson_tmp = await this.getContactPerson(items[item]);
                                        console.log('contactPerson_tmp', contactPerson_tmp)
                                        if (contactPerson_tmp) {
                                            contactPersonName_tmp = contactPerson_tmp.name;
                                            contactPersonphoneNumber_tmp = contactPerson_tmp.phoneNumber;
                                            console.log('contactPersonName_tmp', contactPersonName_tmp)
                                        }
                                    }
                                    newState.push({
                                        id: item,
                                        created: items[item].created,
                                        lastUpdated: items[item].lastUpdated,
                                        postTypeId: items[item].postTypeId,
                                        values: items[item].values,
                                        uid: items[item].uid,
                                        contactPersonName: contactPersonName_tmp,
                                        contactPersonphoneNumber: contactPersonphoneNumber_tmp,
                                        viewed: items[item].viewed,
                                        // posterInfo: posterInfo,
                                        opened: false
                                    });
                                }
                            }
                        }
                        viewMessage.posts = newState;
                        console.log('posts', viewMessage.posts)
                        viewMessage.loadedPosts = true
                    } else {
                        viewMessage.loadedPosts = true
                    }
                });
            } else {
                console.log('proposalsMode')
                //get all my proposals, and save postID of each proposal to array myproposalPostsIDs, then for each postID, get the post and add it to posts
                let myproposalPostsIDs = [];
                itemRef = await fire.database().ref('admin/proposals').orderByChild('proposalerUID').equalTo(this.UID);
                await itemRef.on('value', async (snapshot) => {
                    let items = snapshot.val();
                    console.log('items', items)
                    if (items) {
                        for (let item in items) {
                            myproposalPostsIDs.push(items[item].postId)
                        }
                    }
                    console.log('myproposalPostsIDs', myproposalPostsIDs)
                    for (let i = 0; i < myproposalPostsIDs.length; i++) {
                        let postRef = await fire.database().ref('admin/posts').child(myproposalPostsIDs[i]);
                        await postRef.on('value', async (snapshot) => {
                            let post = snapshot.val();
                            if (post) {
                                if (post.status === this.postsStatus) {
                                    if (post.values && post.postTypeId) {
                                        // let posterInfo = await this.getPosterInfo(post.uid);
                                        viewMessage.posts.push({
                                            id: myproposalPostsIDs[i],
                                            created: post.created,
                                            lastUpdated: post.lastUpdated,
                                            postTypeId: post.postTypeId,
                                            values: post.values,
                                            uid: post.uid,
                                            // posterInfo: posterInfo,
                                            opened: false
                                        });
                                    }
                                }
                            }
                        });
                    }
                    viewMessage.loadedPosts = true
                });
            }
        },
        async getPostTypes() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('admin/postTypes');
            await itemRef.on('value', async (snapshot) => {
                let items = snapshot.val();
                if (items) {
                    let newState = [];
                    for (let item in items) {
                        if (items[item]) {
                            newState.push({
                                id: item,
                                // fieldIds: items[item].fieldIds,
                                name: items[item].name,
                                postTypeExtended: items[item].postTypeExtended,
                            });
                        }
                    }
                    viewMessage.postTypes = newState;
                    if (viewMessage.initiatedPostTypeId != '' && viewMessage.postTypes.length > 0 && viewMessage.initiatedPostTypeId != undefined && viewMessage.initiatedPostTypeId != null) {
                        const selectedType = viewMessage.postTypes.find(type => type.id === viewMessage.initiatedPostTypeId);
                        if (selectedType) {
                            viewMessage.selectedPostTypeId = [{ text: selectedType.name, value: selectedType.id }];
                        }
                    } else {
                        viewMessage.selectedPostTypeId = []
                    }
                    // console.log('postTypes', viewMessage.postTypes)
                }
            });
        },
        getPostType(id) {
            if (!this.postTypes) return ''
            //if id is an array, return an array of postTypes
            if (Array.isArray(id)) {
                let ret = []
                id.forEach(post_id => {
                    let postType = this.postTypes.find(pt => pt.id == post_id.value)
                    if (postType) {
                        ret.push(postType)
                    }
                });
                console.log('ret', ret)
                return ret
            }
            else {
                let postType = this.postTypes.find(pt => pt.id == id)
                if (postType) {
                    return postType
                }
            }
            return ''
        },
        async getPostFields() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('admin/fields/created_fields/addRecord');
            await itemRef.on('value', async (snapshot) => {
                let items = snapshot.val();
                if (items) {
                    let newState = [];
                    for (let item in items) {
                        if (items[item]) {
                            newState.push({
                                id: item,
                                fields: items[item].fields,
                                name: items[item].name,
                                type: items[item].type,
                            });
                        }
                    }
                    viewMessage.postFields = newState;
                    // console.log('postFields', viewMessage.postFields)
                }
            });
        },
        getFieldType(name) {
            if (!this.postFields) return ''
            let postField = this.postFields.find(pt => pt.name == name)
            if (postField) {
                return postField
            }
            return ''
        },
        filterByType(post) {
            if (this.selectedPostTypeId.length === 0) return true
            //this.selectedPostTypeId is an array, i need to check if post.postTypeId is in this array
            for (let i = 0; i < this.selectedPostTypeId.length; i++) {
                if (this.selectedPostTypeId[i].value == post.postTypeId) {
                    return true
                }
            }
            // return post.postTypeId == this.selectedPostTypeId.value
        },
        handleFilter(filterParams) {
            // Здесь вы можете обработать параметры фильтра
            console.log(filterParams);
            this.placeFrom = filterParams.cityFrom
            this.radiusFrom = filterParams.radiusFrom
            this.placeTo = filterParams.cityTo
            this.radiusTo = filterParams.radiusTo
        },
        getCorrectShortName(place) {
            let city = null;
            let street_number = null;
            let route = null;
            let neighborhood = null;
            let point_of_interest = null;
            if (!place) return ''
            if (!place.address_components) {
                if (place.name) {
                    return place.name
                }
                return "no name"
            }
            if (place.address_components && place.address_components.length == 0) {
                return place.name
            }

            for (let i = 0; i < place.address_components.length; i++) {
                let component = place.address_components[i];

                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
                if (!city) {
                    if (component.types.includes('administrative_area_level_2')) {
                        city = component.long_name;
                    }
                }
                if (component.types.includes('neighborhood')) {
                    neighborhood = component.long_name;
                }
                if (component.types.includes('street_number')) {
                    street_number = component.long_name;
                }
                if (component.types.includes('route')) {
                    route = component.long_name;
                }
                if (component.types.includes('point_of_interest')) {
                    point_of_interest = component.long_name;
                }
            }
            let showAdress = '';
            if (route) {
                showAdress += 'רח' + '\' ' + route;
            }
            if (street_number) {
                showAdress += ' ' + street_number;
            }
            if (route || street_number) {
                showAdress += ', '
            }
            if (city) {
                showAdress += city + ' ';
            }
            if (neighborhood) {
                showAdress += '(' + neighborhood + ')'
            }
            if (!place.buissness_name && point_of_interest) {
                place.buissness_name = point_of_interest
            }
            if (city != null) {
                return city
            } else if (place.buissness_name) {
                return `${place.buissness_name}`
            } else {
                return showAdress
            }
        },
        formatDateToDayView(date) {
            if (!date) return '';
            let ret = '';
            if (date.dateFrom) {
                ret += format(new Date(date.dateFrom), 'dd-MM-yyyy')
            }
            if (date.dateTo) {
                ret += ' - ' + format(new Date(date.dateTo), 'dd-MM-yyyy')
            }
            return ret
        },
        getDateFromForSorting(dateRange) {
            return dateRange && dateRange.dateFrom ? new Date(dateRange.dateFrom) : new Date(0); // Возвращаем начало времен, если дата не задана
        },
        changeSort(column) {
            this.sortByStar = false;
            this.sortBylastUpdated = false;
            // console.log('changeSort', column);
            if (this.sortColumn === column) {
                if (this.sortOrder === 'asc') {
                    this.sortOrder = 'desc';
                } else if (this.sortOrder === 'desc') {
                    this.sortColumn = null;
                    this.sortOrder = null;
                }
            } else {
                this.sortColumn = column;
                this.sortOrder = 'asc';
            }
        },
        doSortByStar() {
            this.sortColumn = null;
            this.sortBylastUpdated = false;
            if (this.sortByStar) {
                if (this.sortOrder === 'asc') {
                    this.sortOrder = 'desc';
                } else if (this.sortOrder === 'desc') {
                    this.sortOrder = null;
                    this.sortByStar = false;
                }
            } else {
                this.sortByStar = true;
                this.sortOrder = 'asc';
            }
        },
        dosortBylastUpdated() {
            this.sortColumn = null;
            this.sortByStar = false;
            if (this.sortBylastUpdated) {
                if (this.sortOrder === 'asc') {
                    this.sortOrder = 'desc';
                } else if (this.sortOrder === 'desc') {
                    this.sortOrder = null;
                    this.sortBylastUpdated = false;
                }
            } else {
                this.sortBylastUpdated = true;
                this.sortOrder = 'asc';
            }
        },
        getFieldValueByfieldId(post, fieldId) {
            // console.log('getFieldValueByfieldId', post, fieldId)
            if (!post || !post.values || !fieldId) return ''
            let field = post.values[fieldId.id]
            if (!field) return ''
            let value = field.value
            if (!value) return ''
            if (fieldId.type == 'DateRangeInput') {
                return this.formatDateToDayView(value)
            }
            if (fieldId.type == 'PlaceInput') {
                return this.getCorrectShortName(value)
            }
            // if (fieldId.type == 'SelectOneInput') {
            //     let option = field.fields.find(f => f.id == value)
            //     if (option) {
            //         return option.name
            //     }
            // }
            if (fieldId.type == 'SelectMultipleInput') {
                let ret = ''
                value.forEach(val => {
                    let option = field.fields.find(f => f.id == val)
                    if (option) {
                        ret += option.name + ', '
                    }
                });
                return ret
            }
            return value
        },
        formatDate(date) {
            if (!date) return '';
            let ret = '';
            ret += format(new Date(date), 'dd-MM-yyyy')
            return ret
        },
        async getMyStaredPosts() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('admin/users/' + this.UID + '/staredPosts');
            await itemRef.on('value', async (snapshot) => {
                let items = snapshot.val();
                if (items) {
                    viewMessage.myStaredPosts = items;
                } else {
                    viewMessage.myStaredPosts = []
                }
            });
        },
        async add_remove_star(post_id) {
            console.log(post_id)
            //check if post_id is inside this.startedPosts
            if (this.myStaredPosts.includes(post_id)) {
                //remove it from this.startedPosts
                this.myStaredPosts = this.myStaredPosts.filter(item => item !== post_id)
                await fire.database().ref('admin/users/' + this.UID + '/staredPosts').set(this.myStaredPosts);
            } else {
                //add it to this.startedPosts
                this.myStaredPosts.push(post_id)
                await fire.database().ref('admin/users/' + this.UID + '/staredPosts').set(this.myStaredPosts);
            }
            console.log(this.myStaredPosts)
        },
        isStaredPost(post_id) {
            return this.myStaredPosts.includes(post_id)
        },
        async getPosterInfo(uid) {
            let name = "";
            console.log('getPosterInfo', uid)
            // let viewMessage= this;
            let res = await fire.database().ref('users/' + uid).on('value', async (snapshot) => {
                const rawData = snapshot.val();
                let posterInfo = rawData;


                // if (!posterInfo) return name;
                if (posterInfo && posterInfo.companyInfo && posterInfo.companyInfo.companyName) {
                    name = posterInfo.companyInfo.companyName
                } else {
                    if (posterInfo && posterInfo.info && posterInfo.info.name) {
                        name = posterInfo.info.name
                    }
                }
                console.log('name', name)
                // return name;
            });
            console.log('res', res)
            return name;
        },
        openPost(postID) {
            let post_tmp = this.posts.find(post => post.id === postID);
            post_tmp.opened = !post_tmp.opened;
            console.log('post_tmp', post_tmp);
            if (post_tmp.opened && !this.isPostViewedByMe(postID)) {
                //add to this post in firebace (admin>posts>[postID]>viewed) a record with the current user UID
                let msg_tmp = { "uid": this.UID, "date": new Date().toISOString() }
                fire.database().ref('admin/postsViewed/' + postID).push(msg_tmp);
            }

        },
        async getPostsViewed() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('admin/postsViewed');
            await itemRef.on('value', async (snapshot) => {
                let items = snapshot.val();
                if (items) {
                    viewMessage.postsViewed = items;
                } else {
                    viewMessage.postsViewed = []
                }
            });
        },
        isPostViewedByMe(postID) {
            // console.log('isPostViewedByMe', postID)
            if (!this.postsViewed) return false
            let postViewed = this.postsViewed[postID]
            if (!postViewed) return false
            let viewed = Object.values(postViewed).find(viewed => viewed.uid === this.UID)
            if (viewed) return true
            return false
        }
    },
    watch: {
        selectedPostTypeId() {
            this.filters = {}
            this.sortColumn = null
            // this.sortOrder = 'asc'
            this.showExtarFilters = false
            this.sortBylastUpdated = true;
            this.sortOrder = 'desc';
        }
    }

}
</script>

<style>
.pac-target-input {
    outline: 1px solid #000;
    margin: 10px;
    border-radius: 5px;
    padding: 5px;
}

.span-width {
    width: calc(100% / 8);
}

.span-width-min {
    width: calc(100% / (8*1));
}

.showPointer:hover {
    cursor: pointer;
}
</style>